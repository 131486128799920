<template>
  <v-col class="pa-5">
    <div class="d-flex flex-row align-center mb-3">
      <span class="font-weight-bold">{{ title }}</span>
      <v-chip class="cyan lighten-4 font-weight-medium rounded-lg ml-2" label>
        #{{ item.serial_number }}
      </v-chip>
    </div>
    <v-card class="card-wrap tp-border-thin px-3 py-2" flat>
      <div>
        <div class="">{{ item.product_name }}</div>
        <div>
          <span class="font-weight-bold">SKU: {{ item.option_sku }} </span>
          <span>{{ item.product_code }}</span>
          <span v-if="item.option_name"> - </span>
          <span class="tag-p__mb-0" v-html="item.option_name"></span>
        </div>
        <div>
          <span class="font-weight-bold">Giá bán: </span>
          <span>{{ item.price | formatCurrency }}</span>
        </div>
        <div>
          <span class="font-weight-bold">Phiếu nhập: </span>
          <span>{{ item.hdn_code }}</span>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["title", "item"],
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  }
};
</script>
